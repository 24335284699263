import axios from "axios"
import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, useColorScheme } from 'react-native'
import { TickerProvider, TickerView } from "./Ticker";
import { BASE_URL } from "./Constants";
import { Colors } from "./App";

export const AccountProvider = () => {

    var accounts = [];

    let getAccounts = async token => {
        let config = {};
        if (token) {
            config.headers = { token };
        }
        let response = await axios.get(`${BASE_URL}/api/accounts`, config);
        accounts = JSON.parse(response.data).data;
        console.log(accounts);
        return accounts;
    }

    return {
        getAccounts,
        accounts: () => {
            return accounts;
        }
    }
}

export const BalanceView = props => {
    const isDarkMode = useColorScheme() === 'dark';
    const textColor = (opacity = 'FF') =>
      (isDarkMode ? Colors.light : Colors.black) + opacity;
    // const acctProvider = props.acctProvider;
    const tickerProvider = props.tickerProvider;
    const symbol = props.symbol;
    const balance = props.balance;
    const [total, setTotal] = useState("");
    useEffect(() => {
        let interval;
        if (total.length == 0) {
            interval = setInterval(() => {
                try {
                    let tickers = tickerProvider.ticker();
                    let tickerData = tickers[symbol];
                    // console.log({symbol, tickers, tickerProvider});
                    let total = balance * tickerData.price;
                    setTotal(`$${total.toFixed(2)}`);
                } catch(e) {
                    // console.error("ERROR", tickerProvider);
                    setTotal(`$${parseFloat(balance).toFixed(2)}`);
                }
            }, 1000)
        }
        return () => {
            setTotal("");
            clearInterval(interval);
        }
    }, []);
    return (
        <View style={props.style}>
            <View style={{flex: 1, flexDirection: "row", borderTopWidth: 2, borderTopColor: textColor("AA")}}>
                <Text style={{flex: 1, width: 10, textAlign: "right", color: Colors[isDarkMode?"white":"black"]}}>=</Text>
                <Text style={{flex: 1, textAlign: "right", color: Colors[isDarkMode?"white":"black"]}}>{total}</Text>
            </View>
        </View>
    )
};

export const AccountTotalBalanceView = props => {
    const acctProvider = props.acctProvider;
    const tickerProvider = props.tickerProvider;
    const [total, setTotal] = useState("");
    const updateTotal = useCallback(() => {
        try {
            let accounts = acctProvider.accounts().filter(a => a.balance > 0);
            let tickers = tickerProvider.ticker();
            let balances = Object.entries(tickers).map(([symbol, data]) => {
                let _symbol = symbol.split("-")[0];
                let price = (data || { price: 1 }).price;
                let account = accounts.find(a => a.currency.startsWith(_symbol));
                let balance = (account || { balance: 0 }).balance;
                // console.log({price, account, balance, symbol, data});
                return price * balance;
            });
            let total = balances.reduce((p,c) => (p||0)+c);
            setTotal(`$${total.toFixed(2)}`);
        } catch (e) {
            // console.error(e);
            setTotal("$-.--");
        }
    });
    useEffect(() => {
        var invterval = setInterval(updateTotal, 1000);
        return () => {
            setTotal("$-.--");
            clearInterval(invterval);
        }
    }, []);
    return <Text style={props.style}>{total}</Text>;
}

export const AccountView = props => {
    const acctProvider = props.acctProvider;
    const tickerProvider = props.tickerProvider;
    const isDarkMode = useColorScheme() == "dark";
    const [ accounts, setAccounts ] = useState([]);
    useEffect(() => {
        if (accounts.length == 0 ) {
            getAccounts();
        }
    }, []);
    const getAccounts = async () => {
        let response = await acctProvider.getAccounts(props.token);
        setAccounts(response);
    }
    let acctViews = [];
    let cellStyle = {
        flex: 1,
        flexDirection: "column",
        width: "50%",
        alignSelf: "stretch",
        overflow: "hidden",
        color: isDarkMode ? Colors.white : Colors.black
    }
    for (let account of accounts.filter(a => a.balance > 0)) {
        acctViews.push(
            <View key={account.id} style={{flex: 1, flexDirection: "column", alignContent: "stretch",width: "100%"}}>
                {/* <View style={{flex: 1, flexDirection: "column", width: "200"}}> */}
                <View style={{width: 80}}>
                    <Text style={props.highlight}>{account.currency}: </Text>
                </View>
                <View style={{flex: 1, alignSelf: "stretch", flexDirection: "row", width: "100%"}}>
                    <Text style={cellStyle}>{account.balance}</Text>
                    <TickerView style={{...cellStyle, textAlign: "right"}} provider={tickerProvider} symbol={`${account.currency}-USDT`} />
                </View>
                <BalanceView style={[cellStyle, {width: "100%"}]} tickerProvider={tickerProvider} balance={account.balance} symbol={`${account.currency}-USDT`}/>
            </View>
        )
    }
    return (
        <View style={{...props.style, flex: 1, alignSelf: "stretch", width: "100%"}}>
            {acctViews}
        </View>
    );
}