import axios from "axios"
import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import { BASE_URL } from "./Constants";

export const TickerProvider = () => {
    var tickers = [];

    const getAllTickers = async () => {
        await getTicker();
        return tickers;
    }

    const getTicker = async symbol => {
        try {
            let response = await axios.get(`${BASE_URL}/api/ticker${symbol?"?symbol="+symbol:""}`);
            // console.log(response);
            if (response.hasOwnProperty('data')) {
                let parsed = response.data;
                if (parsed.hasOwnProperty('data')) {
                    if (symbol) {
                        tickers[symbol] = parsed.data;
                    } else {
                        tickers = parsed.data.ticker;
                    }
                }
            }
            return symbol ? tickers[symbol] : tickers;
        } catch (e) {
            // TODO: handle error
            return symbol ? tickers[symbol] : tickers;
        }
    };

    return {
        getAllTickers,
        getTicker,
        ticker: () => {
            return tickers;
        }
    };

};

export const TickersView = props => {
    const [tickers, setTickers] = useState([]);
    const provider = TickerProvider();
    useEffect(() => {
        if (!tickers.hasOwnProperty("BTC-USDT")) {
            provider.getAllTickers().then( _tickers => {
                setTickers(_tickers);
            });
        }
        return () => {
            setTickers([])
        }
    }, []);
    let tickerViews = [];
    for (let ticker of tickers) {
        tickerViews.push(
            <View>
                <Text>{ticker.symbol}: {ticker.averagePrice}</Text>
            </View>
        );
    }
    return (
        <View>
            {tickerViews}
        </View>
    )
};

export const TickerView = props => {
    const [data, setData] = useState({});
    const provider = props.provider;
    useEffect(() => {
        let interval;
        if (!(data && data.hasOwnProperty("time"))) {
            interval = setInterval(() => {
                provider.getTicker(props.symbol).then(_data => {
                    if (interval === null) return;
                    setData(_data);
                });
            }, 1000);
        }
        return () => {
            setData({});
            clearInterval(interval);
            interval = null;
        }
    }, [])
    let price = (data && data.hasOwnProperty("time")) ? `$${data.price}` : "";
    return (
        <Text style={props.style}>{price}</Text>
    );
}

