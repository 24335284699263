import axios from "axios"
import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, useColorScheme, Platform } from 'react-native'
import { BASE_URL } from "./Constants";
import { Colors } from "./App";

// EXAMPLE RESPONSE:
// GET: BASE_URL/api/futures
// {
//     "code": "200000",
//     "data": {
//         "accountEquity": 1070.0933890784,
//         "unrealisedPNL": -6.0417,
//         "marginBalance": 1076.1350890784,
//         "positionMargin": 841.13291439,
//         "orderMargin": 0,
//         "frozenFunds": 0,
//         "availableBalance": 228.9604746884,
//         "currency": "USDT"
//     }
// }

export const FuturesAccountProvider = () => {

    var data = [];

    let getAccountData = async token => {
        let config = {};
        if (token) {
            config.headers = { token };
        }
        let response = await axios.get(`${BASE_URL}/api/futures`, config);
        data = response.data.data;
        // console.log(data);
        return data;
    }

    return {
        getAccountData,
        data: () => {
            return data;
        }
    }
};

export const FuturesAccountDashboard = ({
    token,
    futuresAccountProvider,
    style,
}) => {
    const defaultData = {
        "accountEquity": "-.--",
        "unrealisedPNL": "-.--",
        "marginBalance": "-.--",
        "positionMargin": "-.--",
        "orderMargin": "-.--",
        "frozenFunds": "-.--",
        "availableBalance": "-.--",
        "currency": "USDT"
    };
    const [data, setData] = useState(defaultData);
    useEffect(() => {
        let interval;
        const getAccountData = async () => {
            try {
                let accountData = //futuresAccountProvider.data();
                    await futuresAccountProvider.getAccountData();
                console.log("ACCOUNT_DATA", accountData);
                accountData.accountEquity = parseFloat(accountData.accountEquity).toFixed(2);
                accountData.unrealisedPNL = parseFloat(accountData.unrealisedPNL).toFixed(2);
                accountData.positionMargin = parseFloat(accountData.positionMargin).toFixed(2);
                accountData.marginBalance = parseFloat(accountData.marginBalance).toFixed(2);
                accountData.availableBalance = parseFloat(accountData.availableBalance).toFixed(2);
                setData(accountData);
            } catch (e) {
                console.error("ERROR", e);
                setData(data);
            }
        }
        // if (data == defaultData) {
        interval = setInterval(getAccountData, 10000)
        getAccountData();
        // }
        return () => {
            setData(defaultData);
            clearInterval(interval);
        }
    }, []);

    return (
        <View style={{ flex: 3, width: "100%" }}>
            <View style={{ flex: 1, flexDirection: "row", width: "100%", marginBottom: Platform.OS == "web" ? 160 : 20 }}>
                <View style={{ flex: 2, alignItems: 'center' }}>
                    <Text style={{ flex: 1, flexDirection: "row", width: "100%", textAlign: 'center', lineHeight: 100, fontSize: 100 }}>{data.accountEquity || "0.00"}</Text>
                    <Text style={{ flex: 1, flexDirection: "row", width: "100%", textAlign: 'center', fontSize: 20 }}>Equity</Text>
                </View>
            </View>
            <View style={{ flex: 2, flexDirection: "row", width: "100%", marginBottom: Platform.OS == "web" ? 120 : 20 }}>
                <View style={{ flex: 1, width: "50%", alignItems: 'center' }}>
                    <Text style={{ flex: 1, flexDirection: "row", fontSize: 32 }}>{data.unrealisedPNL || "0.00"}</Text>
                    <Text style={{ flex: 1, flexDirection: "row", fontSize: 18 }}>Open Profit</Text>
                </View>
                <View style={{ flex: 1, width: "50%", alignItems: 'center' }}>
                    <Text style={{ flex: 1, flexDirection: "row", fontSize: 32 }}>{data.positionMargin || "0.00"}</Text>
                    <Text style={{ flex: 1, flexDirection: "row", fontSize: 18 }}>In Trade</Text>
                </View>
            </View>
            <View style={{ flex: 2, alignContent: 'center', marginBottom: Platform.OS == "web" ? 120 : 20}}>
                <Text style={{ flex: 1, flexDirection: "row", width: "100%", textAlign: 'center', fontSize: 80 }}>{data.marginBalance || "0.00"}</Text>
                <Text style={{ flex: 1, flexDirection: "row", width: "100%", textAlign: 'center', fontSize: 20 }}>Account Balance</Text>
            </View>
            <View style={{ flex: 1, alignContent: 'center' }}>
                <Text style={{ flex: 1, flexDirection: "row", width: "100%", textAlign: 'center', fontSize: 80 }}>{data.availableBalance || "0.00"}</Text>
                <Text style={{ flex: 1, flexDirection: "row", width: "100%", textAlign: 'center', fontSize: 20 }}>Cash Out Amount</Text>
            </View>
        </View>
    )
}