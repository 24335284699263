import axios from "axios";
import React, { useState } from "react";
import {Button, TextInput, Text, View, useColorScheme } from "react-native";
import { Colors, Section } from "./App";
import { BASE_URL } from "./Constants";

export const UserProvider = props => {
    var _username = "";
    var _password = "";
    return {
        code: "",
        username: _username,
        password: _password,
        acctProvider: props.acctProvider,
        setUsername: username => {
            _username = username;
        },
        setPassword: password => {
            _password = password;
        },
        doLogin: (setToken, setError) => {
            console.log(this);
            axios.post(`${BASE_URL}/api/login`, {
                user: _username,
                password: _password
            }).then(response => {
                console.log(response);
                let data = response && response.data
                if (!data) {
                    setError("No Data..?");
                }
                if (data.success) {
                    if (data.result) {
                        setError("");
                        setToken(data.result);
                    } else {
                        setError("Try again..?");
                    }
                } else {
                    setError(data.message)
                }
            }).catch(reason => {
                setError(reason)
                console.error(reason);
            })
        }
    };
}

export const LoginView = props => {
    const isDarkMode = useColorScheme() == "dark";
    const hightlight = isDarkMode ? Colors.light : Colors.dark;
    const userProvider = props.userProvider;
    const [showForm, setShowForm] = useState(false);
    return (
        <View style={{width: "100%"}}>
            { showForm && (
                <View>
                    <Section title="Login:"/>
                    <TextInput style={{color: hightlight, padding: 5, margin: 10}} type="email" placeholder="Username:" onChangeText={userProvider.setUsername} />
                    <TextInput style={{color: hightlight, padding: 5, margin: 10}} type="password" secureTextEntry={true} placeholder="Password:" onChangeText={userProvider.setPassword} />
                    <Text style={{color: hightlight, marginVertical: 10, marginHorizontal: 20}}>{props.error}</Text>
                </View>
            )}
            <View style={{alignContent: "center", width: "100%", marginTop: 20}}>
                <Button color={hightlight} onPress={() => !showForm ? setShowForm(true) : userProvider.doLogin(props.setToken, props.setError)} title="Login" />
            </View>
        </View>
    )
}