/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, { useCallback, useEffect, useState } from 'react';
import {
  AsyncStorage as _AsyncStorage,
  Button,
  ImageBackground,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const AsyncStorage = _AsyncStorage || localStorage;

import { AccountView, AccountProvider, AccountTotalBalanceView } from './Account';
import { FuturesAccountDashboard, FuturesAccountProvider } from './FuturesAccount';
import { SymbolsView } from './Symbols';
import { TickerProvider, TickersView } from './Ticker';
import { LoginView, UserProvider } from './User';

export const Colors = {
  black: '#000000',
  white: '#ffffff',
  dark: 'rgb(24, 29, 39)',
  // dark: '#331f72',
  // light: 'rgb(136, 188, 189)',
  light: 'rgb(242,	174,	121)',
};

const acctProvider = AccountProvider();
const tickerProvider = TickerProvider();
const userProvider = UserProvider({ acctProvider });
const futuresAccountProvider = FuturesAccountProvider();

var styles;
var backgroundStyle;
var imageStyles;

const getItem = async key => {
  try {
    return await AsyncStorage.getItem(key);
  } catch(e) {
    console.log("StorageError", e.message);
    return "";
  }
}

let isDarkMode;

const App = () => {

  isDarkMode = useColorScheme() === 'dark';

  if (typeof document != "undefined")
    document.body.style.backgroundColor = Colors[useColorScheme()];

  useEffect(colorScheme => {
    initialzieStyles(colorScheme == "dark");
  }, [useColorScheme()])

  initialzieStyles(isDarkMode);

  const [loginToken, setLoginToken] = useState("");
  const [errorString, setError] = useState("");

  const setToken = useCallback(_token => {
    try {
      AsyncStorage.setItem("token", _token);
    } catch (e) {}
    setLoginToken(_token);
    console.log({_token, loginToken})
  }, [loginToken]);

  const logout = useCallback(() => setToken(""));

  useEffect(() => {
    getItem("token").then(token => (token != loginToken) && setToken(token || ""));
    return () => setToken("");
  }, []);

  var loggedIn = loginToken !== "";

  return (
    <SafeAreaView id="root" style={[backgroundStyle, {minHeight: "100%"}]}>
      <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'} />
      <MainScrollView>
        <ImageBackground
          accessibilityRole={'image'}
          source={require('./images/logo.png')}
          style={imageStyles.background}
          imageStyle={imageStyles.logo}>
          {/* <Text style={imageStyles.text}>YachtOffice</Text> */}
        </ImageBackground>
        <View style={{
          // ...backgroundStyle,
          flex: 1,
          marginBottom: 20,
          width: "100%"}}>
          { !loggedIn
          ? <View>
            <Section title="Step One">
              Get your ass some Crypto, and put the
              <Text style={styles.highlight}> Bacht</Text> to work for you!
            </Section>
            <Section title="Send Your BTC Here:" style={{marginBottom: 20}}>
              {/* <ReloadInstructions /> */}
              <Text style={styles.highlight}>
                3PcHazULoccyzk7LeRKCNCJE1janqYCwQP
              </Text>
            </Section>
            <LoginView userProvider={userProvider} setError={setError} setToken={setToken} error={errorString} />
          </View>
          : (
            <View>
              <View style={{flex: 1}}>
                <View style={{flex: 20, flexDirection: "row", width: "100%", marginBottom: 20, padding: 20}}>
                  <FuturesAccountDashboard token={loginToken} futuresAccountProvider={futuresAccountProvider} />
                </View>
                <View style={{flex: 1, flexDirection: "row", width: "100%", marginBottom: 20, padding: 20}}>
                  <Text style={[
                    styles.sectionTitle,
                    {
                      color: isDarkMode ? Colors.white : Colors.black,
                      flex: 1
                    },
                  ]}>Spot Accounts:</Text>
                  <AccountTotalBalanceView acctProvider={acctProvider} tickerProvider={tickerProvider} style={[
                    styles.sectionTitle,
                    {
                      color: isDarkMode ? Colors.white : Colors.black,
                      flex: 1, alignSelf: "flex-end", textAlign: "right"
                    }]
                  } />
                </View>
                <View style={{paddingHorizontal: 20}}>
                  <AccountView token={loginToken} acctProvider={acctProvider} tickerProvider={tickerProvider} highlight={styles.highlight} />
                </View>
                <View style={{marginVertical: 20, opacity: 0.9}}>
                  <Button color={textColor()} title="Log Out" onPress={logout} />
                </View>
              </View>
            </View>
          )
        }
        </View>
      </MainScrollView>
    </SafeAreaView>
  );
};


const initialzieStyles = isDarkMode => {
  styles = StyleSheet.create({
    sectionContainer: {
      marginTop: 32,
      paddingHorizontal: 24,
      backgroundColor: isDarkMode ? Colors.dark : Colors.light,
    },
    sectionTitle: {
      fontSize: 24,
      fontWeight: '600',
      color: textColor(),
    },
    sectionDescription: {
      marginTop: 8,
      fontSize: 18,
      fontWeight: '400',
      color: textColor(),
    },
    highlight: {
      fontWeight: '700',
      color: textColor('AA'),
    },
  });
  
  imageStyles = StyleSheet.create({
    background: {
      paddingBottom: 128,
      paddingTop: 192,
      // paddingHorizontal: 0,
      marginBottom: 10,
      overflow: 'hidden'
    },
    logo: {
      opacity: isDarkMode ? 0.2 : 1,
      overflow: 'visible',
      resizeMode: 'cover',
      /*
        * These negative margins allow the image to be offset similarly across screen sizes and component sizes.
        *
        * The source logo.png image is 512x512px, so as such, these margins attempt to be relative to the
        * source image's size.
        */
      marginTop: 0,
      marginBottom: 0,
      marginStart: 0,
      position: 'absolute',
      top: -16,
      bottom: -92,
      left: -2024 + 512,
      alignSelf: 'flex-start',
      maxHeight: 336,
    },
    text: {
      fontSize: 40,
      fontWeight: '600',
      textAlign: 'center',
      color: Colors.white,
    },
  });
  
  backgroundStyle = {
    backgroundColor: isDarkMode ? Colors.dark : Colors.light,
    height: '100%',
  };
}

const textColor = (opacity = 'FF') =>
  (isDarkMode ? Colors.light : Colors.black) + opacity;

const MainScrollView = props => {
  const [refreshing, setRefreshing] = useState(false);
  const onRefresh = useCallback(() => {
    setRefreshing(true);
    acctProvider.getAccounts().then(accounts => {
      setRefreshing(false);
    });
  });
  return (
    <KeyboardAwareScrollView
        contentInsetAdjustmentBehavior="automatic"
        style={backgroundStyle}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
          />
        }
    >{
      props.children
    }</KeyboardAwareScrollView>
  )
}

export const Section = ({children, title, style}): React.Node => {
  return (
    <View style={[styles.sectionContainer, style]}>
      <Text
        style={[
          styles.sectionTitle,
          {
            color: isDarkMode ? Colors.white : Colors.black,
          },
        ]}>
        {title}
      </Text>
      <Text
        style={[
          styles.sectionDescription,
          {
            color: isDarkMode ? Colors.white : Colors.black,
          },
        ]}>
        {children}
      </Text>
    </View>
  );
};

export default App;
