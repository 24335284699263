import axios from "axios"
import React, { useEffect, useState } from 'react'
import { View, Text } from 'react-native'
import { BASE_URL } from "./Constants";

export const SymbolProvider = () => {
    var symbols = [];

    const getSymbols = async () => {
        try {
            let response = await axios.get(`${BASE_URL}/api/symbols`);
            // console.log(response)
            if (response.hasOwnProperty('data')) {
                let parsed = response.data;
                if (parsed.hasOwnProperty('data')) {
                    symbols = parsed.data;
                    return symbols;
                }
            }
            return [];
        } catch (e) {
            // handle error
            return [];
        }
    };

    return {
        getSymbols,
        symbols: () => {
            return symbols;
        }
    };

};

export const SymbolsView = props => {
    const [symbols, setSymbols] = useState([]);
    const provider = SymbolProvider();
    useEffect(() => {
        if (symbols.length == 0) {
            provider.getSymbols().then( _symbols => {
                setSymbols(_symbols);
            });
        }
        return () => {
            setSymbols([])
        }
    }, []);
    let symbolViews = [];
    for (let symbol of symbols.filter(s => s.quoteCurrency == "USDT")) {
        symbolViews.push(
            <View>
                <Text>{symbol.name}</Text>
            </View>
        );
    }
    return (
        <View>
            {symbolViews}
        </View>
    )
};

